import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { ForgotReqBody, LoginReqBody } from 'server/auth';

/**
 * Local storage keys. Does not include auth, as we use the extension as the 
 * single source of truth for autentication.
 */
export enum LSKey {
  user = 'user',
}

export const hexToRgba = (hex: string, opacity: number): string | null => {
  let c: string[];
  let hexCode: number;
  if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
      c = hex.substring(1).split('');
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      hexCode = parseInt('0x' + c.join(''));
      return `rgba(${[(hexCode >> 16) & 255, (hexCode >> 8) & 255, hexCode & 255].join(',')}, ${opacity.toString()})`;
  }
  return null
}

export const createLoginArgs = (loginParam: string, password: string): LoginReqBody => {
  // eslint-disable-next-line no-control-regex
  const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  const phoneNumberRegex = /(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})/;
  if (emailRegex.test(loginParam)) return { email: loginParam, password };
  if (phoneNumberRegex.test(loginParam)) return { phoneNumber: loginParam, password };
  else return { username: loginParam, password };
}

export const createForgotPasswordArgs = (forgotPasswordParam: string): ForgotReqBody | null => {
  // eslint-disable-next-line no-control-regex
  const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  const phoneNumberRegex = /(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})/;
  if (emailRegex.test(forgotPasswordParam)) return { email: forgotPasswordParam };
  if (phoneNumberRegex.test(forgotPasswordParam)) return { phoneNumber: forgotPasswordParam };
  else return null;
}

export const displayUrl = (url: string) => {
  let hostname = new URL(url).hostname;
  if (hostname.slice(0, 4) === 'www.') hostname = hostname.slice(4);
  let path = new URL(url).pathname;
  if (path.slice(-1) === '/') path = path.slice(0, -1)
  return `${hostname}${path}`
}

export const calculateTimeAgo = (datetime: number) => {
  TimeAgo.addLocale(en);
  const timeAgo = new TimeAgo('en-US');
  return timeAgo.format(datetime, 'twitter');
}
