import logo from 'assets/logo-large-full.png';
import React from 'react';
import './style.scss';

const Loading = () => {
  return (
    <div className="TbdLoading">
      <img className="TbdLoading__Logo" src={ logo } alt="" />
    </div>
  )
};

export default Loading