import React from 'react';
import { Link } from 'react-router-dom';
import './style.scss';

const Error = () => {
	return (
    <div className='TroveError'>
      <div className='TroveError__Emoji'>
        <span role="img" aria-label="blush-emoji">👩‍🚒 🚒 👨‍🚒</span>
      </div>
      <br/>
      <div className="TroveError__Title">Error</div>
      <div className="TroveError__Message">
        <p style={{textAlign: 'center'}}>
          Something went wrong! We just sent a report to our servers, we'll try and fix it ASAP.
          <br/><br/>
          In the meantime, head back to the homepage at
          <Link to='/' className="TbdLink" style={{marginLeft: '5px'}}>
            <strong>trove.so</strong>
          </Link>
        </p>
      </div>
    </div>
	)
};

export default Error;