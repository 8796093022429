import { action, observable } from 'mobx';
import User from 'state/entities/User';
import IUser from 'state/models/IUser';
import { LSKey } from 'utils';
import { MessageType } from 'utils/chrome/external';

class UserStore {

  @observable user?: User;
  // userCache: Map<string, IUser>; 
  
  constructor() {
    window.addEventListener('message', (e) => {
      if (e.source !== window) return;
      const { data } = e;
      const { user } = data;
      if (data.type && (data.type === MessageType.UpdateProfile)) {
        userStore.setUser(user!)        
      }
    }, false);
  }

  @action setUser = (u: IUser) => {
    const user = new User(u)
    this.user = user;
    // this.userCache.set(user.id, u)
  }

  @action clear = () => {
    // this.userCache = new Map()
    this.user = undefined;
  }

/***************************
******* PERSISTENCE *******
***************************/

saveLocal = (): void => {
  window.localStorage.setItem(LSKey.user, JSON.stringify(userStore.user!));
};

clearLocal = (): void => {
  window.localStorage.removeItem(LSKey.user);
};

getLocal = (key: LSKey) => {
  if (key === LSKey.user) {
    const user = window.localStorage.getItem(LSKey.user)
    if (!user) return undefined
    return JSON.parse(user)
  }
  return null
}

  // /**
  //  * Returns a specific user.
  //  * @param {string} userId 
  //  * NEEDS TO BE ADJUSTED FOR CHANGING VALUES LIKE LIKES, FOLLOWERS, FOLLOWING
  //  */
  // @action
	// getUser = async (userId: string): Promise<User | null> => {
  //   if (userId === '') return null;
  //   const cachedUser = this.userCache.get(userId);
  //   if (cachedUser) return new User(cachedUser);
  //   else {
  //     const res = await getUser(userId)
  //     if (!res.success) return null;
  //     if (this.userCache.size >= USER_CACHE_MAX_SIZE) {
  //       const cacheKeyIterator = this.userCache.keys();
  //       const oldestKey = cacheKeyIterator.next().value;
  //       this.userCache.delete(oldestKey);
  //     }
  //     this.userCache.set(res.user!.id, res.user!);
  //     return new User(res.user!);
  //   }
  // };
}

const userStore = new UserStore()
export default userStore;