import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  EyeInvisibleOutlined,
  EyeOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import React, { forwardRef, useEffect, useState } from "react";
import "../style.scss";
import "./style.scss";

type InputProps = {
  autofocus?: boolean;
  className?: string;
  disabled?: boolean;
  helpTooltip?: any;
  icon?: any;
  id?: string;
  isPassword?: boolean;
  label?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => Promise<void> | void;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => Promise<void> | void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => Promise<void> | void;
  onPressEnter?: (text?: string) => Promise<void> | void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => Promise<void> | void;
  options?: any[];
  placeholder?: string;
  showValidation?: boolean;
  size?: "fit" | "fill";
  style?: object;
  validation?: Validation;
  validationErrorMessage?: string;
  value?: string;
};

export enum Validation {
  Success = "Success",
  Error = "Error",
  Loading = "Loading",
}

type InputRef = HTMLInputElement;

const Input = forwardRef<InputRef, InputProps>(
  (
    {
      autofocus = false,
      className = "",
      disabled = false,
      helpTooltip,
      icon = null,
      id,
      isPassword,
      label,
      onBlur = () => {},
      onChange = () => {},
      onFocus = () => {},
      onPressEnter = () => {},
      onKeyUp = () => {},
      options = [],
      placeholder = "",
      showValidation,
      size = "fit",
      style = {},
      validation,
      validationErrorMessage,
      value,
    },
    ref
  ) => {
    const sizeStyle = size === "fill" ? { minWidth: "100%" } : {};
    const customStyle = { ...style, ...sizeStyle };

    const [input, setInput] = useState(value ? value : "");
    const [focused, setFocused] = useState(autofocus);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
      if (value !== undefined) setInput(value);
    }, [value]);

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (value === undefined) setInput(e.target.value);
      await onChange(e);
    };

    const handleFocus = async (e: React.FocusEvent<HTMLInputElement>) => {
      setFocused(true);
      await onFocus(e);
    };

    const handleBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
      setFocused(false);
      await onBlur(e);
    };

    const handleSubmit = async (e: React.KeyboardEvent<HTMLInputElement>) => {
      e.preventDefault();
      if (value === undefined) setInput("");
      const tempInput = input;
      await onPressEnter(tempInput);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.keyCode === 13) handleSubmit(e);
    };

    const toggleShowPassword = () => setShowPassword(!showPassword);

    const renderValidation = () => {
      switch (validation) {
        case Validation.Success: {
          return <CheckCircleTwoTone twoToneColor="#00C781" />;
        }
        case Validation.Error: {
          return <CloseCircleTwoTone twoToneColor="#FF4040" />;
        }
        case Validation.Loading: {
          return <LoadingOutlined />;
        }
      }
    };

    return (
      <>
        <div
          className={`${className} TbdInput__Wrapper 
          ${
            showValidation &&
            validation === Validation.Error &&
            validationErrorMessage &&
            "TbdInput__Wrapper--error"
          }
          ${disabled && "TbdInput__Wrapper--disabled"}`}
          style={customStyle}
        >
          {label && (
            <div className="TbdInput__LabelWrapper">
              {label}
              {helpTooltip && (
                <div className="TbdInput__Tooltip">{helpTooltip}</div>
              )}
            </div>
          )}
          <div
            className={`TbdInput__InputWrapper ${
              options.length > 0 && "TbdInput__InputWrapper--options"
            }`}
            style={options.length === 0 ? { transition: "150ms" } : {}}
          >
            {icon && (
              <div
                className="TbdInput__Icon"
                style={focused ? { color: "#0d77e2" } : { color: "#dddddd" }}
              >
                {icon}
              </div>
            )}
            <input
              id={id || "TroveInputId"}
              className={`TbdInput ${disabled && "TbdInput--disabled"}`}
              type={isPassword && !showPassword ? "password" : "text"}
              placeholder={placeholder}
              disabled={disabled}
              ref={ref}
              style={style}
              onFocus={async (e) => await handleFocus(e)}
              autoFocus={autofocus}
              value={input}
              onChange={async (e) => await handleChange(e)}
              onBlur={async (e) => await handleBlur(e)}
              onKeyDown={async (e) => await handleKeyDown(e)}
              onKeyUp={onKeyUp}
            />
            {isPassword && (
              <div className="TbdInput__Password" onClick={toggleShowPassword}>
                {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
              </div>
            )}
            {showValidation && validation && (
              <div className="TbdInput__Validation">{renderValidation()}</div>
            )}
          </div>
          {options.length > 0 && (
            <div className="TroveSearchBar__Options">{options}</div>
          )}
        </div>
        {showValidation &&
          validation === Validation.Error &&
          validationErrorMessage && (
            <div className="TbdInput__Validation--error">
              {validationErrorMessage}
            </div>
          )}
      </>
    );
  }
);

export default Input;
