import { DEFAULT_USER_COLORS } from "constants/index";
import { action, observable } from "mobx";
import {
  checkValidUsername,
  forgotPassword,
  IUsernameRes,
  login,
  resetPassword,
  signup,
  SignupReqBody,
} from "server/auth";
import { createLoginArgs } from "utils";
import {
  Message,
  MessageType,
  sendMessageToExtension,
} from "utils/chrome/external";
import { Referrer } from "../models/IUser";
import userStore from "./UserStore";

class AuthStore {
  @observable hasExtension?: boolean;
  @observable isAuthenticated?: boolean;
  @observable token?: string;

  constructor() {
    // check if we have the extension and are logged in
    sendMessageToExtension({ type: MessageType.Exists }).then((res) => {
      if (res) {
        this.hasExtension = true;
        sendMessageToExtension({ type: MessageType.isAuthenticated }).then(
          (res) => {
            if (res === null) this.hasExtension = false;
            const { isAuthenticated, token, user } = res as Message;
            if (isAuthenticated) {
              this.token = token;
              userStore.setUser(user!);
              userStore.saveLocal();
              window.analytics.identify(user!.id);
              this.isAuthenticated = true;
            } else this.isAuthenticated = false;
          }
        );
      } else this.hasExtension = false;
    });

    window.addEventListener(
      "message",
      (e) => {
        if (e.source !== window) return;
        const { data } = e;
        const { token, user } = data;
        if (data.type && data.type === MessageType.Login) {
          this.token = token;
          userStore.setUser(user!);
          userStore.saveLocal();
          window.analytics.identify(user!.id);
          this.isAuthenticated = true;
        }
        if (data.type && data.type === MessageType.Logout) {
          userStore.clearLocal();
          this.isAuthenticated = false;
          this.token = undefined;
          userStore.user = undefined;
          window.location.reload();
        }
        if (data.type && data.type === MessageType.Exists) {
          this.hasExtension = true;
        }
      },
      false
    );
  }

  /*********************
   ***** AUTH ROUTES *****
   **********************/

  @action
  signup = async (
    email: string,
    password: string,
    referrer: Referrer | string
  ): Promise<{ success: boolean; message?: string }> => {
    // Validation
    // let vDisplayName = this.checkValidDisplayName(displayName);
    let vEmail = this.checkValidEmail(email);
    let vPassword = this.checkValidPassword(password);
    // let vUsername = await this.checkValidUsername(username);

    // if (!vDisplayName.success) return { success: false, message: vDisplayName.message }
    if (!vEmail.success) return { success: false, message: vEmail.message };
    if (!vPassword.success)
      return { success: false, message: vPassword.message };
    // if (!vUsername.success) return { success: false, message: vUsername.message }

    const vExtension = (await sendMessageToExtension({
      type: MessageType.Exists,
    })) as { success: boolean };
    if ((vExtension as any).success === false) {
      return { success: false, message: "Must download extension first!" };
    } else this.hasExtension = true;

    // Signup
    const args: SignupReqBody = {
      // displayName,
      // username,
      email,
      password,
      referrer,
      color:
        DEFAULT_USER_COLORS[
          Math.floor(Math.random() * DEFAULT_USER_COLORS.length)
        ],
    };
    const res = await signup(args);

    // login on webpage and send login to extension
    if (res.success && res.user) {
      const { user, token } = res;
      this.token = token;
      userStore.setUser(user);
      userStore.saveLocal();
      window.analytics.identify(user.id, { email });
      window.analytics.track("Signed Up!", { timestamp: Date.now() });
      this.isAuthenticated = true;
      // send login details to extension to store
      await sendMessageToExtension({
        type: MessageType.Login,
        user,
        token,
      });
    }
    return res;
  };

  @action
  login = async (username: string, password: string) => {
    // Validation
    if (username.length === 0)
      return { success: false, message: "Please enter a username!" };
    if (password.length === 0)
      return { success: false, message: "Please enter a password!" };

    // Login
    const args = createLoginArgs(username, password);
    const res = await login(args);

    // Login on webpage and send login to extension
    if (res.success && res.user) {
      const { user, token } = res;
      this.token = res.token;
      userStore.setUser(user);
      userStore.saveLocal();
      window.analytics.identify(user.id, {
        email: username,
      });
      window.analytics.track("Login Attempt", { timestamp: Date.now() });
      this.isAuthenticated = true;
      // send login details to extension to store
      await sendMessageToExtension({
        type: MessageType.Login,
        user,
        token,
      });
    }
    return res;
  };

  @action
  resetPassword = async (
    token: string,
    password: string
  ): Promise<{ success: boolean; message?: string }> => {
    // Validation
    let vPassword = this.checkValidPassword(password);
    if (!vPassword.success)
      return { success: false, message: vPassword.message };

    // Reset password
    const res = await resetPassword(token, { password });

    // login on webpage and send login to extension
    if (res.success && res.user) {
      const { user, token } = res;
      this.token = token;
      userStore.setUser(user);
      userStore.saveLocal();
      window.analytics.identify(user.id);
      window.analytics.track("Login after Reset Password", {
        timestamp: Date.now(),
      });
      this.isAuthenticated = true;
      // send login details to extension to store
      await sendMessageToExtension({
        type: MessageType.Login,
        user,
        token,
      });
    }
    return res;
  };

  @action
  forgotPassword = async (email: string) => {
    let vEmail = this.checkValidEmail(email);
    if (!vEmail.success) return { success: false, message: vEmail.message };
    window.analytics.track("Sending forgot password email", {
      timestamp: Date.now(),
    });
    return await forgotPassword({ email });
  };

  /****************************
   ***** SIGNUP VALIDATION *****
   *****************************/

  checkValidDisplayName = (
    name: string
  ): { success: boolean; message?: string } => {
    if (name.length === 0) {
      return {
        success: false,
        message: "Please enter a name!",
      };
    }
    return { success: true };
  };

  checkValidUsername = async (username: string): Promise<IUsernameRes> => {
    if (username.length === 0) {
      return {
        success: false,
        message: "Please enter a username!",
      };
    } else if (username.length < 3) {
      return {
        success: false,
        message: "Your username must be at least 3 characters long!",
      };
    } else if (username.length > 20) {
      return {
        success: false,
        message: "Your username must be less than 20 characters long!",
      };
    } else if (!/^[a-zA-Z0-9_]{3,20}$/.test(username)) {
      return {
        success: false,
        message: "Username can only contain letters, numbers, and underscores!",
      };
    }
    return await checkValidUsername(username);
  };

  checkValidEmail = (email: string): { success: boolean; message?: string } => {
    const reEmail = /(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    if (email.length === 0) {
      return {
        success: false,
        message: "Please enter an email!",
      };
    } else if (!reEmail.test(email)) {
      return {
        success: false,
        message: "Please ensure your email address is valid.",
      };
    }
    return { success: true };
  };

  checkValidPassword = (
    password: string
  ): { success: boolean; message?: string } => {
    if (password.length === 0) {
      return {
        success: false,
        message: "Please enter a password!",
      };
    } else if (password.length < 6) {
      return {
        success: false,
        message: "Password must be at least 6 characters long",
      };
    }
    return { success: true };
  };
}

const authStore = new AuthStore();
export default authStore;
