import { QuestionCircleFilled } from "@ant-design/icons";
import { Alert, Tooltip } from "antd";
import { Button, Input } from "components/_lib";
import { Validation } from "components/_lib/Input";
import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Referrer } from "state/models/IUser";
import useStores from "state/stores";
import "./style.scss";

interface LocationState {
  newUser: boolean;
}

interface SignupProps extends RouteComponentProps<{}, {}, LocationState> {
  defaultReferrer?: Referrer;
  onBack?: () => void;
  onSignup: () => void;
  showBackButton?: boolean;
  goToLogin?: () => void;
  defaultEmail?: string;
  defaultDisplayName?: string;
}

const Signup = ({
  defaultReferrer,
  history,
  goToLogin,
  showBackButton = false,
  onBack = () => {},
  defaultEmail = "",
  defaultDisplayName = "",
  onSignup,
}: SignupProps) => {
  const [displayName, setDisplayName] = useState(defaultDisplayName);
  const [username, setUsername] = useState("");
  const [referrer, setReferrer] = useState<Referrer | string>(
    defaultReferrer || Referrer.Unknown
  );
  const [email, setEmail] = useState(defaultEmail);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [usernameHasValidation, setUsernameHasValidation] = useState(false);
  const [usernameValidation, setUsernameValidation] = useState<Validation>(
    Validation.Loading
  );
  const [usernameValidationError, setUsernameValidationError] = useState("");

  const { authStore, userStore } = useStores();

  const handleSignup = async () => {
    window.analytics.track("Clicked 'Signup'", { timestamp: Date.now() });
    setLoading(true);
    const res = await authStore.signup(email, password, referrer);
    setLoading(false);
    if (res.success) onSignup();
    else setErrorMessage(res.message || "Error signing up. Try again!");
  };

  // const handleUsernameChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setUsernameHasValidation(true);
  //   setUsernameValidation(Validation.Loading);
  //   setUsername(e.target.value);
  //   let res = await authStore.checkValidUsername(e.target.value);
  //   if (res.success) {
  //     if (!res.exists) setUsernameValidation(Validation.Success);
  //     else {
  //       setUsernameValidation(Validation.Error);
  //       setUsernameValidationError('This username is already taken.');
  //     }
  //   } else {
  //     setUsernameValidation(Validation.Error);
  //     setUsernameValidationError(res.message || 'Sorry, this username is restricted.');
  //   }
  // };

  return (
    <>
      {/* <div className="TroveAuth__FormItem">
        <Input
          label="Display name"
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
        />
      </div>
      <div className="TroveAuth__FormItem">
        <Input
          label='Username'
          size='fill'
          value={username}
          onChange={handleUsernameChange}
          showValidation={usernameHasValidation}
          validation={usernameValidation}
          validationErrorMessage={usernameValidationError}
        />
      </div> */}
      <div className="TroveAuth__FormItem">
        <Input
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="TroveAuth__FormItem">
        <Input
          label="Password"
          value={password}
          isPassword={true}
          helpTooltip={
            <Tooltip title="Your password must be at least 6 characters long.">
              <QuestionCircleFilled />
            </Tooltip>
          }
          size="fill"
          onChange={(e) => setPassword(e.target.value)}
          onPressEnter={handleSignup}
        />
      </div>
      {/* <div className="TroveAuth__FormItem">
        <Input 
          label="Password"
          value={password}
          isPassword={true}
          helpTooltip={(
            <Tooltip title='Your password must be at least 6 characters long.'>
              <QuestionCircleFilled />
            </Tooltip>
          )}
          size='fill'
          onChange={(e) => setPassword(e.target.value)}
          onPressEnter={handleSignup}
        />
      </div> */}
      <div className="TroveAuth__ButtonWrapper">
        {showBackButton && (
          <span style={{ marginRight: "5px" }}>
            <Button label="Back" type="secondary" onClick={onBack} />
          </span>
        )}
        <Button loading={loading} label="Signup" onClick={handleSignup} />
      </div>
      {goToLogin && (
        <div className="TroveAuth__RedirectLink--signup" onClick={goToLogin}>
          Already have an account? Login here.
        </div>
      )}
      <div
        className={`TbdError__Wrapper ${
          !errorMessage && "TbdError__Wrapper--hide"
        }`}
      >
        <Alert
          showIcon
          message={errorMessage}
          type="error"
          className="TbdError"
        />
      </div>
    </>
  );
};

export default withRouter(Signup);
