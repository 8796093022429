import { Alert } from 'antd';
import { Button, Input } from 'components/_lib';
import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useStores from 'state/stores';
import '../style.scss';
import './style.scss';

interface ForgotPasswordProps extends RouteComponentProps {
  goToLogin: ( () => void );
}

const ForgotPassword = ({ history, goToLogin  }: ForgotPasswordProps) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);

  const { authStore } = useStores();

  const handleEmailInput = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);

  const handleForgotPassword = async () => {
    setLoading(true)
    const res = await authStore.forgotPassword(email)
    setLoading(false)
    if (res.success) setShowSuccessScreen(true);
    else setErrorMessage(res.message || 'Invalid email. Try again!')
  }
  
  return (
    <div className="TroveAuth">
      <div className="TroveAuth__Header">
        <div className="TroveAuth__LogoWrapper">
          <div className="TroveAuth__LogoText">
            Trove
          </div>
          <div className="TroveAuth__LogoUnderscore"/>
        </div>
      </div>
      <div className="TroveAuth__BodyWrapper">
        <div className="TroveAuth__Body">
          <div className="TroveAuth__Form">
          {showSuccessScreen ? (
            <>
              <div className='TbdAuth__SuccessMessage'>
                Password reset sent!
              </div>
              <div className='TroveSuccess__ButtonWrapper'>
                <Button
                  className='TroveAuth__Success'
                  label='Back'
                  size='fill'
                  type='secondary'
                  onClick={goToLogin}
                />
              </div>
            </>
          ) : (
            <>
              <div className="TroveAuth__FormItem">
                <Input
                  label="Email"
                  size='fill'
                  value={email}
                  onChange={handleEmailInput}
                  onPressEnter={handleForgotPassword}
                />
              </div>
              <div className='TroveAuth__ButtonWrapper'>
                <Button
                  loading={loading}
                  type='primary'
                  label='Submit'
                  onClick={handleForgotPassword}
                />
                <Button
                  className='TroveAuth__ForgotPasswordCancel'
                  label='Cancel'
                  type='secondary'
                  onClick={goToLogin}
                />
              </div>
              <div className={`TbdError__Wrapper ${!errorMessage && 'TbdError__Wrapper--hide'}`}>
                <Alert showIcon message={errorMessage} type="error" className="TbdError" />
              </div>
            </>
          )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(ForgotPassword);