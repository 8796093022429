import { action, observable } from 'mobx';
import IUser, { Referrer } from '../models/IUser';

export default class User implements IUser {
  // @observable public displayName: string
  // @observable public username: string; // alphanum and underscores, 3 < username < 20 characters
  @observable public color: string; // Hex code
  @observable public referrer: Referrer;
  @observable public followers?: number;
  @observable public following?: number;
  @observable public likes?: number;
  @observable public followsYou?: boolean;
  @observable public followingProfile?: boolean;
  public id: string;
  public creationDatetime: number

  constructor(u: IUser) {
    this.id = u.id;
    // this.displayName = u.displayName;
    // this.username = u.username;
    this.creationDatetime = u.creationDatetime;
    this.color = u.color;
    this.referrer = u.referrer;
    if (u.followsYou !== undefined) this.followsYou = u.followsYou;
    if (u.followingProfile !== undefined) this.followingProfile = u.followingProfile;
    if (u.followers !== undefined) this.followers = u.followers;
    if (u.following !== undefined) this.following = u.following;
    if (u.likes !== undefined) this.likes = u.likes;
  }

  @action
  decrementLikes = (num: number = 1) => {
    if (this.likes !== undefined) this.likes -= num;
  }

  @action
  incrementLikes = (num: number = 1) => {
    if (this.likes !== undefined) this.likes += num;
  }

  @action
  followUser = () => {
    if (this.followers !== undefined) this.followers += 1;
    if (this.followingProfile !== undefined) this.followingProfile = true;
  }

  @action
  unfollowUser = () => {
    if (this.followers !== undefined) this.followers -= 1;
    if (this.followingProfile !== undefined) this.followingProfile = false;
  }
};
