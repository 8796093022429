import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';
import '../style.scss';
import './style.scss';

interface ButtonProps {
  label: string,
  onClick: ((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => Promise<void> | void),
  className?: string,
  disabled?: boolean,
  iconLeft?: React.ReactNode,
  iconRight?: React.ReactNode,
  innerStyle?: object,
  loading?: boolean,
  onMouseOut?: ((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => Promise<void> | void),
  onMouseOver?: ((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => Promise<void> | void),
  size?: 'fit' | 'fill',
  style?: object,
  type?: 'primary' | 'secondary',
}

const Button = ({
  disabled=false, 
  iconLeft, 
  iconRight, 
  label, 
  loading,
  onClick, 
  className='',
  onMouseOut=( () => {} ),
  onMouseOver=( () => {} ),
  size='fit',
  style={}, 
  type='primary', 
  innerStyle={},
}: ButtonProps) => {
  const sizeStyle = size === 'fill' ? {minWidth: '100%', minHeight: '100%'}: {}
  const disabledStyle = disabled 
    ? {cursor: 'not-allowed', opacity: 0.8, backgroundColor: '#dddddd'}
    : {}
  const customStyle = {...style, ...disabledStyle, ...sizeStyle}
  const innerCustomStyle = {...disabledStyle, ...innerStyle}
  
  const handleClick = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    await onClick(e)
  }
  return (
    <div
      onClick={!disabled ? handleClick : () => {}}
      onMouseOver={!disabled ? onMouseOver : () => {}}
      onMouseOut={!disabled ? onMouseOut : () => {}}
      style={customStyle}
    >
      <button
        className={`${className} TroveButton--${type}`}
        disabled={disabled}
        style={innerCustomStyle}
      >
        {loading && (
          <div className='TroveButton--loading'>
            <LoadingOutlined />
          </div>
        )}
        {iconLeft && (
          <div className='TroveButton__IconLeft'>
            {iconLeft}
          </div>
        )}
        {label}
        {iconRight && (
          <div className='TroveButton__IconRight'>
            {iconRight}
          </div>
        )}
      </button>
    </div>
  )
}

export default Button;