/**
 * Set REACT_APP_NODE_ENV to change settings.
 */
const {
  REACT_APP_BACKEND_ENV,
  REACT_APP_EXTENSION_ENV,
  REACT_APP_BACKEND_URL_PROD,
  REACT_APP_BACKEND_URL_DEV,
  REACT_APP_BACKEND_URL_LOCAL,
  REACT_APP_EXTENSION_ID_PROD,
  REACT_APP_EXTENSION_ID_DEV,
  REACT_APP_EXTENSION_ID_LOCAL,
  REACT_APP_EXTENSION_LINK_PROD,
  REACT_APP_EXTENSION_LINK_DEV,
  REACT_APP_EXTENSION_LINK_LOCAL,
  REACT_APP_ANALYTICS_KEY,
} = process.env;

let BACKEND_URL: string;
let EXTENSION_ID: string;
let EXTENSION_LINK: string;

if (REACT_APP_BACKEND_ENV === "PRODUCTION" && REACT_APP_BACKEND_URL_PROD) {
  BACKEND_URL = REACT_APP_BACKEND_URL_PROD;
} else if (
  REACT_APP_BACKEND_ENV === "DEVELOPMENT" &&
  REACT_APP_BACKEND_URL_DEV
) {
  BACKEND_URL = REACT_APP_BACKEND_URL_DEV;
} else if (REACT_APP_BACKEND_ENV === "LOCAL" && REACT_APP_BACKEND_URL_LOCAL) {
  BACKEND_URL = REACT_APP_BACKEND_URL_LOCAL;
}

if (REACT_APP_EXTENSION_ENV === "PRODUCTION" && REACT_APP_EXTENSION_ID_PROD) {
  EXTENSION_ID = REACT_APP_EXTENSION_ID_PROD;
} else if (
  REACT_APP_EXTENSION_ENV === "DEVELOPMENT" &&
  REACT_APP_EXTENSION_ID_DEV
) {
  EXTENSION_ID = REACT_APP_EXTENSION_ID_DEV;
} else if (
  REACT_APP_EXTENSION_ENV === "LOCAL" &&
  REACT_APP_EXTENSION_ID_LOCAL
) {
  EXTENSION_ID = REACT_APP_EXTENSION_ID_LOCAL;
}

if (REACT_APP_EXTENSION_ENV === "PRODUCTION" && REACT_APP_EXTENSION_LINK_PROD) {
  EXTENSION_LINK = REACT_APP_EXTENSION_LINK_PROD;
} else if (
  REACT_APP_EXTENSION_ENV === "DEVELOPMENT" &&
  REACT_APP_EXTENSION_LINK_DEV
) {
  EXTENSION_LINK = REACT_APP_EXTENSION_LINK_DEV;
} else if (
  REACT_APP_EXTENSION_ENV === "LOCAL" &&
  REACT_APP_EXTENSION_LINK_LOCAL
) {
  EXTENSION_LINK = REACT_APP_EXTENSION_LINK_LOCAL;
}

export { BACKEND_URL, EXTENSION_ID, EXTENSION_LINK, REACT_APP_ANALYTICS_KEY };
