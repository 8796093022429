import Color from 'color';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import User from 'state/entities/User';
import { EditorRef } from '..';
import { getCurrentWord } from '../helpers';
import './styles.scss';

interface DropdownProps {
  data: any[];
  setText: (text: string) => void;
  setDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
  top: number;
  left: number;
}

const Dropdown = forwardRef<EditorRef, DropdownProps>(({
  data,
  setText,
  top,
  left,
  setDropdownOpen
}, ref) => {
  const [itemIdx, setItemIdx] = useState(0);
  const [items, setItems] = useState<any[]>([]);

  useEffect(() => {
    setItems(data);
  }, [data]);

  const autocomplete = useCallback((str: string) => {
    if (!ref) return;
    const ta = (ref as React.MutableRefObject<EditorRef> | null)?.current;
    if (!ta || !ta.textContent) return;

    // Autocomplete logic
    const currWord = getCurrentWord(ta);
    const text1 = ta.value.slice(0, ta.selectionStart); // Up to end of username prefix
    const text2 = str.slice(currWord.length - 1); // Autocompleted username
    const text3 = ta.value.slice(ta.selectionStart); // End of username to end of text
    setText(`${text1}${text2}${text3} `);

    // Set cursor to correct position
    const selectionIdx = text1.length + text2.length + 1;
    ta.setSelectionRange(selectionIdx, selectionIdx);

    // Hide dropdown
    setItems([]);
    setItemIdx(0);
    setDropdownOpen(false);
  }, [ref, setText]);

  const onKeyDownTextarea = useCallback((e: KeyboardEvent) => {
    e.stopPropagation();
    if (!items || items.length === 0) return;
    switch (e.key) {
      case 'ArrowUp': {
        e.preventDefault();
        setItemIdx(Math.max(0, itemIdx - 1));
        break;
      }
      case 'ArrowDown': {
        e.preventDefault();
        const newIdx = Math.min(items.length - 1, itemIdx + 1);
        setItemIdx(newIdx);
        break;
      }
      case 'Enter':
      case 'Tab': {
        e.preventDefault();
        // autocomplete(items[itemIdx].username);
        break;
      }
      case 'Escape': {
        e.preventDefault();
        setItems([]);
        setItemIdx(0);
        break;
      }
    }
  }, [items, autocomplete, itemIdx]);

  useEffect(() => {
    let node = (ref as React.MutableRefObject<EditorRef> | null)?.current;
    if (node) node.addEventListener('keydown', onKeyDownTextarea);
    return () => {
      if (node) node.removeEventListener('keydown', onKeyDownTextarea);
    }
  }, [ref, onKeyDownTextarea]);

  const renderItem = (item: any, idx: number) => {
    if (item instanceof User) {
      return (
        <button
          className={`TroveDropdown__SuggestedUser 
            ${idx === itemIdx ? 'TroveDropdown__SuggestedUser--selected' : ''}`
          }
          key={item.id}
          // onClick={() => autocomplete(item.username)}
        >
          <div className="TroveSuggestedUser__Left">
            <div className="TroveSuggestedUser__UserBubble" style={{
              backgroundColor: item.color,
              color: Color(item.color).isLight() ? 'black' : 'white'
            }}>
              {/* {item.username[0]} */}
            </div>
          </div>
          <div className="TroveSuggestedUser__Right">
            {/* <p className="TroveSuggestedUser__DisplayName">{item.displayName}</p> */}
            <p className="TroveSuggestedUser__Username" style={{ color: item.color }}>
              {/* {`@${item.username}`} */}
            </p>
          </div>
        </button>
      );
    }
  };

  return (
    <div className="TroveFrontend__Dropdown" style={{top: `${top}px`, left: `${left}px`}}>
      {items.map((item, idx) => renderItem(item, idx))}
      {items.length === 0 && (
        <div className="TroveDropdown__NoUsers">No users found</div>
      )}
    </div>
  );
});

export default Dropdown;
