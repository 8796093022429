import React, { forwardRef, useEffect, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import User from 'state/entities/User';
import getCaretCoordinates from 'textarea-caret';
import { isOsKeyPressed } from 'utils/os';
import Dropdown from './Dropdown';
import { getCurrentWord, getSuggestedUsers } from './helpers';
import './styles.scss';

interface EditorProps {
  value: string;
  setText: (text: string) => void;
  onSubmit: (text: string) => Promise<void>;
  className?: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  disabled?: boolean; // need to write styling for this mode
  style?: object;
  autoFocus?: boolean;
  rows?: number;
}

export type EditorRef = HTMLTextAreaElement | null;

const Editor = forwardRef<EditorRef, EditorProps>(({
  onChange,
  setText,
  onSubmit,
  value,
  placeholder,
  className,
  disabled=false,
  style={},
  autoFocus=false,
  onFocus=() => {},
  onBlur=() => {},
  rows,
}, ref) => {
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [suggestedUsers, setSuggestedUsers] = useState<User[]>([]);
  const customStyle = {
    ...(disabled ? {} : {}),
    ...style,
  }

  useEffect(() => {
    if (autoFocus && ref) {
      const selectionIdx = value.length;
      (ref as React.MutableRefObject<EditorRef>).current?.setSelectionRange(
        selectionIdx,
        selectionIdx
      );
    }
  }, [autoFocus, ref, value])

  const stopPropagation = (e: React.KeyboardEvent<HTMLTextAreaElement>) => e.stopPropagation();

  const handleOnChange = async (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e);
    const users = await getSuggestedUsers(e.target);
    if (users === null) {
      setDropdownOpen(false);
      setSuggestedUsers([]);
    } else {
      const textArea = document.getElementById('TroveFrontend__Editor') as HTMLTextAreaElement;
      if (textArea) {
        const caret = getCaretCoordinates(textArea, textArea.selectionEnd);
        setTop(caret.top + caret.height);
        if (!dropdownOpen) {
          const currentWord = getCurrentWord(textArea);
          let leftAdjustment = 0;
          if (currentWord.indexOf('@') > -1 && currentWord.length > 2) {
            leftAdjustment = currentWord.length * 5
          }
          setLeft(caret.left - 20 - leftAdjustment);
        }
      }
      setDropdownOpen(true);
      setSuggestedUsers(users);
    }
  };

  const onClick = async (e: React.MouseEvent<HTMLTextAreaElement, MouseEvent>) => {
    e.stopPropagation();
    const users = await getSuggestedUsers(e.target as HTMLTextAreaElement);
    if (users === null) {
      setDropdownOpen(false);
      setSuggestedUsers([]);
    } else {
      setDropdownOpen(true);
      setSuggestedUsers(users);
    }
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    e.stopPropagation();
    if (isOsKeyPressed(e) && e.key === 'Enter') {
      // Submit current post
      e.preventDefault();
      onSubmit(value);
    };
  };

  return (
    <>
      <TextareaAutosize
        className={`TroveFrontend__Editor ${className}`}
        id='TroveFrontend__Editor'
        onChange={handleOnChange}
        onClick={onClick}
        onKeyPress={onKeyPress}
        onKeyDown={stopPropagation}
        onKeyUp={stopPropagation}
        value={value}
        placeholder={placeholder || ''}
        ref={ref}
        disabled={disabled}
        style={customStyle}
        autoFocus={autoFocus}
        onFocus={onFocus}
        onBlur={onBlur}
        rows={rows}
      />
      {dropdownOpen && (
        <Dropdown top={top} left={left} data={suggestedUsers} setDropdownOpen={setDropdownOpen} ref={ref as React.MutableRefObject<EditorRef> | null} setText={setText} />
      )}
    </>
  );
});

export default Editor;
