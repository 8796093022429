import Auth from 'components/Pages/Auth';
import Loading from 'components/Pages/Loading';
import { observer } from 'mobx-react';
import React, { ComponentClass, FunctionComponent } from 'react';
import { Redirect, Route } from 'react-router-dom';
import useStores from 'state/stores';

interface PrivateRouteProps {
  component: FunctionComponent | ComponentClass;
  path: string;
  exact?: boolean;
  [propName: string]: any;
}

const PrivateRoute = observer(({ component, exact = false, path, ...rest }: PrivateRouteProps) => {
  const { authStore } = useStores();

  // if (authStore.hasExtension === false) return <Route exact={exact} path={path} {...rest} component={Landing} />;
  if (authStore.hasExtension === false) return <Redirect to="/signup" />;
  else if (authStore.isAuthenticated === false) return <Route exact={exact} path={path} {...rest} component={Auth} />;
  else if (authStore.isAuthenticated === true) return <Route exact={exact} path={path} {...rest} component={component} />;
  return <Loading />
});

export default PrivateRoute;