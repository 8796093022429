import { action, computed, observable } from "mobx";
import { deletePostAndChildren } from 'server/posts';
import Post from 'state/entities/Post';
import Topic from 'state/entities/Topic';
import IPost from 'state/models/IPost';
import ITopic from 'state/models/ITopic';

class PostStore {
  @observable thread?: Post[];
  @observable posts: Post[];
  @computed get topics(): Topic[] {
    const topicMap: Map<string, ITopic> = new Map();
    this.posts!.forEach((p) => {
      p.topics.forEach((t) => topicMap.set(t.id, t))
    });
    return [...topicMap.values()]
      .sort((t1, t2) => t2.lastEdited - t1.lastEdited)
      .map((t) => new Topic(t))
  };

  constructor() {
    this.posts = [];
  };
  
  /**
   * Helper that gets all comments from a post.
   * @param {string} postId 
   */
  getComments = (postId: string): Post[] => {
    const p = this.posts.find((p) => p.id === postId);
    return p?.comments || [];
  }

  /**
   * Helper that gets a comment.
   * @param {string} postId 
   */
  getComment = (commentId: string, parentPostId: string): Post | null => {
    const comments = this.getComments(parentPostId);
    return comments?.find((c) => c.id === commentId) || null;
  }

  /**
   * Helper that removes a comment from the store.
   * @param {string} postId 
   * @param {string} parentPostId 
   */
  @action
  deleteComment = (postId: string, parentPostId: string): void => {
    const p = this.posts.find((p) => p.id === parentPostId);
    if (!p) return;
    p.comments = (p.comments || []).filter((p) => p.id !== postId);
    p.deleteComment()
    this.setPost(p);
  }

  /**
   * Helper that populates store.
   * @param {IPost[]} posts
   */
  @action
  setPosts = (posts: IPost[]) => {
    this.posts = posts
      .sort((p1, p2) => p2.creationDatetime - p1.creationDatetime)
      .map((p) => new Post(p));
  };

  /**
   * Helper that sets thread.
   * @param {IPost[]} posts
   */
  @action
  setThread = (posts: IPost[]) => {
    this.thread = posts
      .sort((p1, p2) => p1.creationDatetime - p2.creationDatetime)
      .map((p) => new Post(p));
  };

  /**
   * Helper that replaces post in postStore with new post. Used when editing a post.
   * @param {Post} post 
   */
  @action
  setPost = (post: Post, parentPostId?: string) => {
    if (parentPostId) {
      const ppI = this.posts.findIndex((p) => p.id === parentPostId);
      if (ppI === -1) return;
      const cI = this.posts[ppI].comments?.findIndex((c) => c.id === post.id)
      if (!cI || cI === -1) return;
      (this.posts[ppI].comments || [])[cI] = post;
    } else {
      const i = this.posts.findIndex((p) => p.id === post.id);
      this.posts[i] = post;
    }
  }

  /**
   * Helper that gets a specific post.
   * @param {Post} post 
   */
  @action
  getPost = (postId: string) => {
    return this.posts.find((p) => p.id === postId);
  }

  /**
   * Helper that replaces post in postStore with new post that has a comment in it.
   * @param {Post} post 
   */
  @action
  addComment = (comment: Post, parentPostId: string, parentParentPostId?: string): void => {
    let parentParentPost;
    let parentPost;
    if (parentParentPostId) {
      parentParentPost = this.posts.find((p) => p.id === parentParentPostId);
      parentPost = parentParentPost?.comments?.find((c) => c.id === parentPostId);
    } else {
      parentPost = this.posts.find((p) => p.id === parentPostId);
    }
    parentPost?.addComment();
    if (parentPost && !parentPost.comments) parentPost.comments = [];
    parentPost?.comments?.unshift(comment);
    if (parentParentPost) this.setPost(parentParentPost);
    else if (parentPost) {
      this.setPost(parentPost);
    }
  }

  /**
   * Helper that clears store when leaving Profile component.
   */
  @action
  clear = () => {
    this.posts = [];
  };

  /**
   * Used to delete a post.
   * @param {string} postId
   */
  @action
  deletePost = async (postId: string, parentPostId?: string) => {
    const res = await deletePostAndChildren(postId);
    if (res.success) {
      if (parentPostId) {
        this.deleteComment(postId, parentPostId);
      } else {
        this.posts = this.posts.filter((p) => p.id !== postId);
      }
    }
    return res;
  };
}

const postStore = new PostStore();
export default postStore;
