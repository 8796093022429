import { api, AxiosRes, BaseParams, BaseRes } from '.';
import IUser, { Referrer } from '../state/models/IUser';

type IAuthRes = AuthRes & AxiosRes;
export type IUsernameRes = UsernameRes & AxiosRes;
export type IAcceptNominationRes = AcceptNominationRes & AxiosRes;

export const signup = async (args: SignupReqBody): Promise<IAuthRes> => {
  return await api.post('/auth/signup', args);
};

export const checkValidUsername = async (username: string): Promise<IUsernameRes> => {
  const args: UsernameReqBody = { username }
  return await api.post('/auth/username', args);
}

export const resetPassword = async (token: string, args: ResetReqBody): Promise<IAuthRes> => {
  const params: ResetReqParams = { token };
  return await api.post(`/auth/reset/${params.token}`, args);
};

export const login = async (args: LoginReqBody): Promise<IAuthRes> => {
  return await api.post('/auth/login', args);
}

export const forgotPassword = async (args: ForgotReqBody): Promise<AxiosRes> => {
  return await api.post('/auth/forgot', args)
}

export const checkCode = async (code: string): Promise<IAcceptNominationRes> => {
  return await api.get(`/auth/nomination/${code}`)
}

/**
 * POST /auth/signup
 */
export interface SignupReqBody {
  color: string;
  // displayName: string;
  password: string;
  // username: string;
  referrer?: Referrer | string;
  email?: string; // either email or phoneNumber
  phoneNumber?: string; // include country code ex. 13017872508
}

/**
 * POST /auth/reset/:token
 */
interface ResetReqBody {
  password: string;
}

/**
 * POST /auth/reset/:token
 */
interface ResetReqParams extends BaseParams {
  token: string;
}

/**
 * POST /auth/username
 */
interface UsernameReqBody {
  username: string;
}

/**
 * POST /auth/login
 */
export interface LoginReqBody {
  email?: string; // either email, phoneNumber or username
  phoneNumber?: string; // include country code ex. 13017872508
  username?: string;
  password: string;
}

/**
 * POST /auth/forgot
 */
export interface ForgotReqBody {
  email?: string; // email or phoneNumber
  phoneNumber?: string; // include country code ex. 13017872508
}

/**
 * POST /auth/signup
 * POST /auth/login
 * POST /auth/reset/:token
 */
type AuthRes = {
  user?: IUser;
  token?: string;
} & BaseRes;

/**
 * POST /auth/username
 */
type UsernameRes = {
  exists?: boolean;
} & BaseRes;

/**
 * GET /auth/nomination/:code
 */
type AcceptNominationRes = {
  id?: string;
  code?: string;
  name?: string;
  email?: string;
  launchName?: string;
} & BaseRes;