import { ArrowRightOutlined, CheckCircleOutlined } from "@ant-design/icons";
import Signup from "components/Pages/Onboarding/signup";
import { Button } from "components/_lib";
import { EXTENSION_LINK } from "constants/secrets";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, useLocation, withRouter } from "react-router-dom";
import { Referrer } from "state/models/IUser";
import useStores from "state/stores";
import "./style.scss";

interface LocationState {
  referrer?: string;
  name?: string;
  email?: string;
  launchName?: string;
  newUser?: boolean;
}

interface OnboardingProps extends RouteComponentProps<{}, {}, LocationState> {}

const Onboarding = observer(({ history, location }: OnboardingProps) => {
  const query = new URLSearchParams(useLocation().search);
  const parseRef = (ref: Referrer | null) => {
    if (ref === null) return Referrer.Unknown;
    else return ref;
  };
  const ref = parseRef(query.get("ref") as Referrer | null);
  const [step, setStep] = useState(0);
  const { authStore, userStore } = useStores();

  // useEffect(() => {
  //   if (!location?.state?.code) history.push('/');
  // }, [location?.state?.code, history])

  const nextButtonDisabled = () => {
    if (!authStore.hasExtension) return true;
    else return false;
  };

  useEffect(() => {
    if (authStore.hasExtension !== undefined) {
      const name = step === 0 ? "Add Extension to Browser" : "Signup";
      window.analytics.page("Onboarding", name, {
        hasExtension: authStore.hasExtension,
        nextButtonDisabled: nextButtonDisabled(),
      });
    }
  }, [step, authStore.hasExtension]);

  const onSignup = () => {
    history.push("/", { newUser: true });
  };

  return (
    <div className="TroveOnboarding">
      <div className="TroveOnboarding__LeftSide">
        <div className="TroveOnboarding__LeftSideInteriorWrapper">
          <div className="TroveAuth__LogoWrapper">
            <div className="TroveAuth__LogoText">Trove</div>
            <div className="TroveAuth__LogoUnderscore" />
          </div>
          <div className="TroveOnboarding__TextWrapper">
            {/* <div className="TroveOnboarding__Text">{`Welcome to Trove${location?.state?.name ? `, ${location.state.name}` : ''}.`}</div> */}
            <div className="TroveOnboarding__Text">
              A chrome extension that lets you{" "}
              <b>create highlights and comments</b> across the internet and send
              them to Notion.
            </div>
          </div>
        </div>
      </div>
      <div className="TroveOnboarding__RightSide">
        <div className="TroveOnboarding__Signup">
          {step === 0 && (
            <div className="TroveOnboarding__StepTextWrapper">
              <span className="TroveOnboarding__Num">1.</span>
              <a
                style={{ marginLeft: "5px" }}
                href={EXTENSION_LINK}
                rel="noopener noreferrer"
              >
                <Button
                  disabled={authStore.hasExtension}
                  innerStyle={{ fontSize: "16px", padding: "18px" }}
                  label="Add Trove to Browser"
                  onClick={() => {
                    window.analytics.track("Clicked 'Add Trove to Browser'", {
                      timestamp: Date.now(),
                    });
                  }}
                />
              </a>
              {authStore.hasExtension && (
                <div className="TroveOnboarding__StepSuccess">
                  <CheckCircleOutlined />
                </div>
              )}
            </div>
          )}
          {/* {step === 1 && (
            <div>
              <div className="TroveOnboarding__StepTextWrapper" style={{alignItems: 'center', justifyContent: 'flex-start'}}>
                <div className="TroveOnboarding__StepText">
                  <span className="TroveOnboarding__Num">2.</span>
                  <span>Pin extension</span>
                </div>
                <span style={{marginLeft: '7px', fontSize: '14px', fontWeight: 500}}>
                  <Tooltip title='Click the puzzle icon on the top right of your browser bar. Look for the Trove extension and click the pin icon next to it.'>
                    <QuestionCircleFilled />
                  </Tooltip>
                </span>
              </div>
              <div className='TroveOnboarding__Subheading1'>
                This will allow you to see notifications when you're tagged.
              </div>
            </div>
          )} */}
          {step === 1 && (
            <>
              <div className="TroveOnboarding__StepTextWrapper--signup">
                <div className="TroveOnboarding__StepText">
                  <span className="TroveOnboarding__Num">2.</span>
                  <span>Signup</span>
                </div>
              </div>
              <Signup
                showBackButton={true}
                onBack={() => setStep(step - 1)}
                onSignup={onSignup}
                defaultReferrer={ref || Referrer.Unknown}
                defaultEmail={location?.state?.email}
                defaultDisplayName={location?.state?.name}
                goToLogin={() => {
                  window.analytics.track(
                    "Clicked 'Already Signed Up? Go to Login'",
                    { timestamp: Date.now() }
                  );
                  history.push("/");
                }}
              />
            </>
          )}
          <div style={{ marginTop: "30px", display: "flex" }}>
            {step > 0 && step !== 1 && (
              <div style={{ marginRight: "5px" }}>
                <Button
                  type="secondary"
                  label="Back"
                  onClick={() => setStep(step - 1)}
                />
              </div>
            )}
            {step < 1 && (
              <div>
                <Button
                  disabled={nextButtonDisabled()}
                  iconRight={<ArrowRightOutlined />}
                  label="Next"
                  onClick={() => {
                    window.analytics.track("Clicked 'Next'", {
                      timestamp: Date.now(),
                    });
                    setStep(step + 1);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export default withRouter(Onboarding);
