import { handleUserSearch } from 'server/users';
import User from 'state/entities/User';

export const getSuggestedUsers = async (ta: HTMLTextAreaElement): Promise<User[] | null> => {
  const currWord = getCurrentWord(ta);
  const match = currWord.match(/^@[a-zA-Z0-9_]{1,20}/);
  if (match) {
    const prefix = match[0].slice(1);
    return await getUsersByPrefix(prefix);
  }

  return Promise.resolve(null);
};

export const getCurrentWord = (ta: HTMLTextAreaElement) => {
  if (!ta || ta.selectionStart !== ta.selectionEnd) return '';

  // Find start
  let startIdx = Math.max(Math.min(ta.selectionStart - 1, ta.value.length - 1), 0);
  while (startIdx > 0) {
    if (ta.value[startIdx].match(/\s/)) {
      startIdx++;
      break;
    }

    startIdx--;
  }

  // Find end
  let endIdx = Math.max(ta.selectionStart, 0);
  while (endIdx < ta.value.length) {
    if (ta.value[endIdx].match(/\s/)) break;
    endIdx++;
  }

  return ta.value.slice(startIdx, endIdx);
};

const getUsersByPrefix = async (prefix: string): Promise<User[]> => {
  try {
    const res = await handleUserSearch(prefix, 5)
    if (res.success) {
      return res.users.map((user) => new User(user));
    } else {
      console.error(`Failed to retrieve usernames with prefix ${prefix}.`);
      return Promise.reject(res.message);
    }
  } catch (err) {
    return Promise.resolve([]);
  }
};
