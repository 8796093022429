import { QuestionCircleFilled } from "@ant-design/icons";
import { Alert, Tooltip } from "antd";
import { Button, Input } from "components/_lib";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import useStores from "state/stores";
import "./style.scss";

interface MatchParams {
  token: string;
}

interface ResetPasswordProps extends RouteComponentProps<MatchParams, {}, {}> {}

const ResetPassword = ({ match, history }: ResetPasswordProps) => {
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const { authStore } = useStores();

  const handleResetPassword = async () => {
    const token = match.params?.token;
    const res = await authStore.resetPassword(token, password);
    if (res.success) history.push("/");
    else setErrorMessage(res.message || "Error resetting password. Try again!");
  };

  useEffect(() => {
    window.analytics.page("Reset Password");
  }, []);

  return (
    <div className="TbdResetPassword">
      <h2 className="TbdResetPassword__Header">Change Your Password</h2>
      <div className="TbdResetPassword__Body">
        <div className="TbdResetPassword__Form">
          <div className="TbdResetPassword__FormItem">
            <Input
              label="New password"
              value={password}
              isPassword={true}
              helpTooltip={
                <Tooltip title="Your password must be at least 6 characters long.">
                  <QuestionCircleFilled />
                </Tooltip>
              }
              size="fill"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="TbdResetPassword__ButtonWrapper">
            <Button label="Reset" onClick={handleResetPassword} />
          </div>
          <div
            className={`TbdError__Wrapper ${
              !errorMessage && "TbdError__Wrapper--hide"
            }`}
          >
            <Alert
              showIcon
              message={errorMessage}
              type="error"
              className="TbdError"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ResetPassword);
