export default interface IUser {
  id: string; // needs to be unique
  // displayName: string;
  // username: string; // alphanum and underscores, 3 < username < 20 characters
  // normalizedUsername: string; // lowercase version for search
  creationDatetime: number;
  color: string; // Hex code with hash
  referrer: Referrer; // where they came from
  following?: number;
  followers?: number;
  likes?: number;
  followsYou?: boolean;
  followingProfile?: boolean;
}

export enum Referrer {
  Unknown = "unknown",
  YC = "yc",
  ProductHunt = "producthunt",
  Twitter = "twitter",
  AliPersonalWebsite = "ali",
  Facebook = "fb",
  Discord = "discord",
  Slack = "slack",
}
