import { CheckCircleOutlined } from "@ant-design/icons";
import { Alert } from "antd";
import annotationExample from "assets/annotationExample.gif";
import React, { useState } from "react";
import useStores from "state/stores";
import { Button, Input } from "../../_lib";
import "./style.scss";

const MobileLanding = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { authStore } = useStores();

  const handleSubmitEmail = (email: string | undefined) => {
    const res = authStore.checkValidEmail(email || "");
    if (res.success) {
      window.analytics.track("Saved Email For Reminder", {
        timestamp: Date.now(),
        email,
      });
      setEmailSent(true);
    } else {
      setErrorMessage(res.message || "Error, try again!");
    }
  };

  return (
    <div className="TroveMobileLanding">
      {/* Logo and Title */}
      <div className="TroveMobileLanding__LogoWrapper">
        <div className="TroveMobileLanding__LogoText">Trove</div>
        <div className="TroveMobileLanding__LogoUnderscore" />
      </div>
      <div className="TroveMobileLanding__Title">
        A chrome extension that lets you <b>create highlights and comments</b>{" "}
        across the internet and send them to Notion.
      </div>
      <img
        src={annotationExample}
        alt=""
        className="TroveMobileLanding__Image"
      />
      {emailSent ? (
        <div className="TroveMobileLanding__Success">
          <div className="TroveMobileLanding__SuccessIcon">
            <CheckCircleOutlined />
          </div>
          <div className="TroveMobileLanding__SuccessMessage">Success!</div>
        </div>
      ) : (
        <div className="TroveMobileLanding__Wrapper">
          {/* Email Input */}
          <Input
            id="TroveMobileLanding__Input"
            className="TroveMobileLanding__Input"
            label="Email"
            onPressEnter={(email) => handleSubmitEmail(email)}
          />
          <Button
            label="Signup"
            onClick={() => {
              const elem = document.getElementById(
                "TroveMobileLanding__Input"
              ) as HTMLInputElement;
              handleSubmitEmail(elem.value);
            }}
            className="TroveMobileLanding__Button"
          />
          <div
            className={`TbdError__Wrapper ${
              !errorMessage && "TbdError__Wrapper--hide"
            }`}
          >
            <Alert
              showIcon
              message={errorMessage}
              type="error"
              className="TbdError"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileLanding;
