import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { getOsKeyChar } from "utils/os";
import Button from "../../_lib/Button";
import "./style.scss";

const Home = observer(() => {
  // const [feedLoading, setFeedLoading] = useState(true);
  // const [showModal, setShowModal] = useState(false);

  // const { postStore, userStore, authStore } = useStores();

  // useEffect(() => {
  //   setFeedLoading(true);
  //   const getPosts = async () => {
  //     const res = await getFeed()
  //     if (res.success) {
  //       const postObjects = res.posts?.map((p) => new PostObject(p)) || []
  //       postStore.setPosts(postObjects);
  //     }
  //     setFeedLoading(false);
  //   }
  //   getPosts();
  //   return () => {postStore.clear()}
  // }, []);

  useEffect(() => {
    window.analytics.page("Home / Instructions");
  }, []);

  return (
    <div className="TrovePage">
      <div className="TroveHome__LeftSide">
        <div className="TroveAuth__LogoWrapper">
          <div className="TroveAuth__LogoText">Trove</div>
          <div className="TroveAuth__LogoUnderscore" />
        </div>
        <div className="TroveHome__Buttons">
          <div className="TroveHome__LeftSection">
            <div className="TroveHome__LeftSectionTitle">Bug</div>
            <div className="TroveHome__LeftSectionRow">
              <span className="TroveHome__LeftSectionRowText">
                Have 10 mins?
              </span>
              <span>
                <a
                  href="https://calendly.com/ali-wetrill/10"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    type="primary"
                    label="Talk to a founder now!"
                    onClick={() => {
                      window.analytics.track(
                        "Clicked 'Talk to a Founder' to talk to Ali",
                        { timestamp: Date.now() }
                      );
                    }}
                  />
                </a>
              </span>
            </div>
            <div className="TroveHome__LeftSectionRow">
              <span className="TroveHome__LeftSectionRowText">Otherwise</span>
              <span>
                <a
                  href="https://research.typeform.com/to/s4K0GMRs"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    type="primary"
                    label="Report here"
                    onClick={() => {
                      window.analytics.track(
                        "Clicked 'Otherwise' to report bug",
                        { timestamp: Date.now() }
                      );
                    }}
                  />
                </a>
              </span>
            </div>
          </div>
          <div className="TroveHome__LeftSection">
            <div className="TroveHome__LeftSectionTitle">General Feedback</div>
            <div>
              <a
                href="https://www.notion.so/simplata/Trove-Community-Board-c2c9fe006c29404b967497ae2d2f3079"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  type="primary"
                  label="Community Board"
                  onClick={() => {
                    window.analytics.track("Clicked 'Community Board'", {
                      timestamp: Date.now(),
                    });
                  }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="TroveHome__RightSide">
        <div className="TroveHome__Steps--full">
          <div className="TroveHomeTitle">Signup Finished!</div>
          <div className="TroveHome__Subheading">
            Now let’s add something to Notion using Trove.
          </div>

          <div className="TroveInstructionSection">
            <div className="TroveHomeSubTitle">Add a Highlight</div>
            <div className="TroveHome__Steps">
              <div className="TroveHome__StepTextWrapper">
                <span className="TroveOnboarding__Num">1.</span>
                <span className="TroveHome__Text">Highlight this text</span>
                {/* <span className="TroveHome__TextEmoji" role='img' aria-labelledby='a'>👈</span> */}
              </div>
              <div className="TroveHome__StepTextWrapper">
                <span className="TroveOnboarding__Num">2.</span>
                <div className="TroveHome__Text">
                  <span>Press</span>
                  <span className="TroveHomeCreateNewHighlight--key">{` ${getOsKeyChar()}+d`}</span>
                </div>
              </div>
              <div
                className="TroveHome__Subheading"
                style={{ marginBottom: "10px", marginTop: "0px" }}
              >
                Repeat the first two steps to capture more highlights.
              </div>
              <div className="TroveHome__StepTextWrapper">
                <span className="TroveOnboarding__Num">3.</span>
                <div className="TroveHome__Text">
                  <span>Press</span>
                  <span className="TroveHomeCreateNewHighlight--key">{` Enter`}</span>
                  <span>to send to Notion</span>
                </div>
              </div>
            </div>
          </div>

          <div className="TroveInstructionSection">
            <div className="TroveHomeSubTitle">Bookmark Page</div>
            <div className="TroveHome__Steps">
              <div className="TroveHome__StepTextWrapper">
                <span className="TroveOnboarding__Num">1.</span>
                <div className="TroveHome__Text">
                  <span>Press</span>
                  <span className="TroveHomeCreateNewHighlight--key">{` ${getOsKeyChar()}+d`}</span>
                </div>
              </div>
              <div className="TroveHome__StepTextWrapper">
                <span className="TroveOnboarding__Num">2.</span>
                <div className="TroveHome__Text">
                  <span>Press</span>
                  <span className="TroveHomeCreateNewHighlight--key">{` Enter`}</span>
                  <span>to send to Notion</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default withRouter(Home);
