import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import User from 'state/entities/User';
import './styles.scss';

interface ContentProps {
  value: string;
  taggedUsers: User[];
  className?: string;
  style?: object;
}

export type ContentRef = HTMLDivElement | null;

const Content = forwardRef<ContentRef, ContentProps>(({
  value,
  taggedUsers,
  className,
  style={},
}, ref) => {

  const renderContentWithTags = (content: string, taggedUsers: User[]) => {
    let tokenizedContent: string[] = [];
    if (!taggedUsers || taggedUsers.length === 0) {
      tokenizedContent = [content];
    } else {
      // const regex = new RegExp(`(${taggedUsers?.map(user => `@${user.username}`).join('|')})`);
      // tokenizedContent = content.split(regex).filter(str => !!str);
    }
    const isUsername = (str: string): boolean => str[0] === '@';
    // const getUserColor = (str: string): string | undefined => {
    //   return taggedUsers?.find(user => user.username === str.slice(1))?.color; 
    // }
    const getUsername = (str: string): string => str.slice(1);

    return (
      <>
        {tokenizedContent.map((subString, i) => {
          return isUsername(subString) ? (
            <Link
              to={`/${getUsername(subString)}`}
              className="TroveContent__UserLink"
              onClick={(e) => e.stopPropagation()}
              // style={{ textDecorationColor: getUserColor(subString) }}
              key={i}
            >
              <span
                style={{
                  // color: getUserColor(subString),
                  fontWeight: 500,
                }}
              >
                {subString}
              </span>
            </Link>
          ) : (
            <span key={i}>{subString}</span>
          )
        })}
      </>
    )
  }

  return (
    <div
      className={`TroveFrontend__EditorContent ${className}`}
      ref={ref}
      style={style}
    >
      {renderContentWithTags(value, taggedUsers)}
    </div>
  );
});

export default Content;
