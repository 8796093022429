import { Alert } from 'antd';
import { Button, Input } from 'components/_lib';
import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useStores from 'state/stores';
import '../style.scss';
import './style.scss';

interface LoginProps extends RouteComponentProps {
  goToSignup: ( () => void );
  goToForgotPassword: ( () => void );
}

const Login = ({ history, goToForgotPassword, goToSignup }: LoginProps) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const { authStore } = useStores();

  const handleUsernameInput = (e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.target.value);
  const handlePasswordInput = (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value);

  const handleLogin = async () => {
    setLoading(true)
    const res = await authStore.login(username, password)
    setLoading(false)
    if (res.success) history.go(0)
    else setErrorMessage(res.message || 'Error logging in. Try again!')
  }
  
  return (
    <div className="TroveAuth">
      <div className="TroveAuth__Header">
        <div className="TroveAuth__LogoWrapper">
          <div className="TroveAuth__LogoText">
            Trove
          </div>
          <div className="TroveAuth__LogoUnderscore"/>
        </div>
      </div>
      <div className="TroveAuth__BodyWrapper">
        <div className="TroveAuth__Body">
          <div className="TroveAuth__Form">
            <div className="TroveAuth__FormItem">
              <Input
                label="Email"
                size='fill'
                value={username}
                onChange={handleUsernameInput}
              />
            </div>
            <div className="TroveAuth__FormItem">
              <Input
                label='Password'
                size='fill'
                value={password}
                onChange={handlePasswordInput}
                isPassword={true}
                onPressEnter={handleLogin}
              />
            </div>
            <div className="TroveAuth__Link--forgot-password" onClick={goToForgotPassword}>Forgot password?</div>
            <div className='TroveAuth__ButtonWrapper'>
              <Button
                loading={loading}
                label='Login'
                onClick={handleLogin}
              />
              <div className="TroveAuth__RedirectLink" onClick={goToSignup}>or signup here</div>
            </div>
            <div className={`TbdError__Wrapper ${!errorMessage && 'TbdError__Wrapper--hide'}`}>
              <Alert showIcon message={errorMessage} type="error" className="TbdError" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Login);