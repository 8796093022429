import Error from 'components/Pages/Error';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import IFrontendError from 'state/models/IFrontendError';
import userStore from 'state/stores/UserStore';
import { createErrorReport, ErrorOrigin, ErrorReqBody } from '../../server/misc';

interface ErrorBoundaryProps extends RouteComponentProps {
  origin: ErrorOrigin
}

interface ErrorBoundaryState {
  hasError: boolean;
  message: string;
}

/**
 * Global handler for all errors. When reporting an error, throw an ExtensionError like so:
 * Note: You only need the stack argument if you are passed an error object
 * 
 * throw new ExtensionError(error.message, 'Error creating highlight, try again!', err.stack)
 */
class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { 
      hasError: false,
      message: ""
    };
  }

  async componentDidCatch(error: IFrontendError, info: React.ErrorInfo) {
    console.error(error.message)
    this.setState({ 
      hasError: true,
      message: error.readableMessage || error.message
    });
    const args: ErrorReqBody = {
      origin: this.props.origin,
      message: error.message,
      error,
      componentStack: info.componentStack
    };
    if (userStore.user?.id) args.userId = userStore.user.id; // TODO
    if (window.location.href) args.url = window.location.href;
    const res = await createErrorReport(args);
    if (res.success) console.info('Error report sent!');
  }

  render() {
    const { hasError } = this.state;
    // ideally flash a message on the screen of the child
    if (hasError) return <Error />
    else return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
