import Error from "components/Pages/Error";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import useStores from "state/stores";
import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
import "./style.scss";

interface AuthProps extends RouteComponentProps {}

const Auth = ({ history }: AuthProps) => {
  const [page, setPage] = useState<"login" | "forgot-password">("login");
  const { authStore, userStore } = useStores();

  useEffect(() => {
    const name = page === "login" ? "Login" : "Forgot Password";
    window.analytics.page(name);
  }, [page]);

  // if (page === 'signup') return <Signup goToLogin={() => setPage('login')} />
  if (page === "login")
    return (
      <Login
        goToForgotPassword={() => setPage("forgot-password")}
        goToSignup={() => history.push("/signup")}
      />
    );
  else if (page === "forgot-password")
    return <ForgotPassword goToLogin={() => setPage("login")} />;
  return <Error />;
};

export default withRouter(Auth);
