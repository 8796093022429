import React from 'react';
import appStore from './AppStore';
import authStore from './AuthStore';
import postStore from './PostStore';
import userStore from './UserStore';

const storesContext = React.createContext({
  appStore,
  authStore,
  postStore,
  userStore,
});

export default () => React.useContext(storesContext);
