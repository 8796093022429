import React, { useEffect } from "react";
import useStores from "state/stores";
import "./style.scss";

const PrivacyPolicy = () => {
  const { authStore, userStore } = useStores();

  useEffect(() => {
    window.analytics.page("Privacy Policy");
  }, [userStore.user, authStore.isAuthenticated]);

  return (
    <div className="TbdPrivacyPolicy">
      <div className="TbdPrivacyPolicy__Header">Privacy</div>

      <div className="TbdPrivacyPolicy__Block">
        <div className="TbdPrivacyPolicy__Block--bold">TLDR:</div>
        <div>
          Trove does not own your data. It's your data, period{" "}
          <span role="img" aria-label="face-with-sunglasses">
            😎
          </span>
        </div>
      </div>
      <div className="TbdPrivacyPolicy__Block">
        Trove provides a way for users to highlight and share content across the
        Internet. At our core, we want to users to capture and organize
        meaningful parts of the Internet, and that requires trust. To that end,
        for us privacy is not an afterthought - it is central to what we do.
        Below is the legal language required for us to operate. We’ve included
        readable translations where necessary. Please give it a read, and feel
        free to send us any feedback!
      </div>

      <div className="TbdPrivacyPolicy__SubHeading">Scope</div>
      <div className="TbdPrivacyPolicy__Block">
        Simplata, Inc. (“Trove,” “we,” “us,” and “our”) provides a way for users
        to highlight and share content across the Internet. Our Privacy Policy
        (“Privacy Policy”) is designed to help you understand how we collect,
        use and share your personal information and to assist you in exercising
        the privacy rights available to you.
      </div>
      <div className="TbdPrivacyPolicy__Block">
        This Privacy Policy covers the personal information we collect about you
        when you use our products or services, or otherwise interact with us,
        including on our website at www.trove.so and chrome extension (“Apps”),
        and our related online and offline offerings (collectively, the
        “Services”). This policy also explains your choices surrounding how we
        use your personal information, which include how you can object to
        certain uses of the information and how you can access and update
        certain information.
      </div>

      <div className="TbdPrivacyPolicy__SubHeading">
        1. Personal Information we collect
      </div>
      <div className="TbdPrivacyPolicy__Block--bold">Account Creation</div>
      <div className="TbdPrivacyPolicy__Block">
        When you create an account or otherwise use the Services, we collect
        your display name, email address, and password.
      </div>

      <div className="TbdPrivacyPolicy__SubHeading">2. Other information</div>
      <div className="TbdPrivacyPolicy__Block--bold">Posts</div>
      <div className="TbdPrivacyPolicy__Block">
        When you create a post using the Services, we collect the highlight, a
        timestamp and the url of the post, as well as user created topics and
        content.
      </div>

      <div className="TbdPrivacyPolicy__SubHeading">
        3. How we use your information
      </div>
      <div className="TbdPrivacyPolicy__Block">
        We need your personal information to communicate with you about your use
        of the Services, including:
      </div>
      <ul className="TbdPrivacyPolicy__Block--list">
        <li>
          Identifying and communicating with you, including providing
          newsletters and marketing materials
        </li>
        <li>Managing your information</li>
        <li>Responding to questions, comments, and other requests</li>
        <li>
          Providing access to certain areas, functionalities, and features of
          our Services
        </li>
        <li>Answering requests for customer or technical support</li>
      </ul>
      <div className="TbdPrivacyPolicy__Block">
        We also use it for administrative purposes, such as:
      </div>
      <ul className="TbdPrivacyPolicy__Block--list">
        <li>
          Measuring interest and engagement in our Services, including analyzing
          your usage of the Services
        </li>
        <li>Developing new products and services and improving the Services</li>
        <li>Ensuring internal quality control and safety</li>
        <li>Authenticating and verifying individual identities</li>
        <li>
          Communicating with you about your account, activities on our Services
          and Privacy Policy changes
        </li>
        <li>
          Preventing and prosecuting potentially prohibited or illegal
          activities
        </li>
        <li>Enforcing our agreements</li>
        <li>Complying with potential legal obligations</li>
      </ul>
      <div className="TbdPrivacyPolicy__Block--bold">
        De-identified and Aggregated Information Use
      </div>
      <div className="TbdPrivacyPolicy__Block">
        We may use personal information and other data about you to create
        de-identified and/or aggregated information. De-identified and/or
        aggregated information is not personal information, and we may use and
        disclose such information in a number of ways, including research,
        internal analysis, analytics, and any other legally permissible
        purposes.
      </div>
      <div className="TbdPrivacyPolicy__Block">
        Translation: We can link information across accounts through a post’s
        URL and user created topics. We use this for internal analysis to help
        decide how to develop the product, such as which websites receive the
        most highlights. When we do link and view this information, we do not
        reveal any personal information to other users.
      </div>
      <div className="TbdPrivacyPolicy__Block">
        In addition, we use the following sub-processors. We will update this
        list as we use other third party tools.
      </div>
      <ul className="TbdPrivacyPolicy__Block--list">
        <li>
          <a className="TbdLink" href="https://aws.amazon.com/">
            Amazon Web Services
          </a>
        </li>
        <li>
          <a className="TbdLink" href="https://github.com/">
            Github
          </a>
        </li>
      </ul>

      <div className="TbdPrivacyPolicy__SubHeading">
        4. Your Privacy Rights and Choices
      </div>
      <div className="TbdPrivacyPolicy__Block">
        Depending upon your location and in accordance with applicable laws, you
        may have the right to:
      </div>
      <ul className="TbdPrivacyPolicy__Block--list">
        <li>
          <strong>Access</strong> personal information about you consistent with
          legal requirements. In addition, you may have the right in some cases
          to receive or have your electronic personal information transferred to
          another party.
        </li>
        <li>
          <strong>Request Correction</strong> of your personal information where
          it is inaccurate or incomplete.
        </li>
        <li>
          <strong>Request Deletion</strong> of your personal information,
          subject to certain exceptions prescribed by law.
        </li>
        <li>
          <strong>Request Restriction or Object to Processing</strong> of your
          personal information, including the right to opt in or opt out of the
          sale of your personal information to third parties.
        </li>
        <li>
          <strong>Not be Discriminated Against</strong> by us for exercising
          your privacy rights.
        </li>
      </ul>

      <div className="TbdPrivacyPolicy__SubHeading">5. Data Retention</div>
      <div className="TbdPrivacyPolicy__Block">
        We store the personal information we receive as described in this
        Privacy Policy for as long as you use our Services or as necessary to
        fulfill the purpose(s) for which it was collected, provide our Services,
        resolve disputes, establish legal defenses, conduct audits, pursue
        legitimate business purposes, enforce our agreements, and comply with
        applicable laws.
      </div>
      <div className="TbdPrivacyPolicy__SubHeading">
        6. Security of your Information
      </div>
      <div className="TbdPrivacyPolicy__Block">
        We take steps to ensure that your information is treated securely and in
        accordance with this Privacy Policy. Unfortunately, no system is 100%
        secure, and we cannot ensure or warrant the security of any information
        you provide to us. To the fullest extent permitted by applicable law, we
        do not accept liability for unauthorized disclosure.
      </div>
      <div className="TbdPrivacyPolicy__Block">
        By using the Services or providing personal information to us, you agree
        that we may communicate with you electronically regarding security,
        privacy, and administrative issues relating to your use of the Services.
        If we learn of a security system’s breach, we may attempt to notify you
        electronically by posting a notice on the Services, by mail or by
        sending an email to you.
      </div>
      <div className="TbdPrivacyPolicy__SubHeading">
        7. California Privacy Notice
      </div>
      <div className="TbdPrivacyPolicy__Block">
        This Privacy Notice applies to California consumers and supplements the
        Privacy Policy.
      </div>
      <div className="TbdPrivacyPolicy__Block--bold">
        California Shine the Light Law
      </div>
      <div className="TbdPrivacyPolicy__Block">
        The California “Shine the Light” law permits users who are California
        residents to request and obtain from us once a year, free of charge, a
        list of the third parties to whom we have disclosed their personal
        information (if any) for their direct marketing purposes in the prior
        calendar year, as well as the type of personal information disclosed to
        those parties. To make such a request from us, if entitled, please use
        the contact information listed below.
      </div>
      <div className="TbdPrivacyPolicy__Block--bold">
        California Consumer Privacy Act (CCPA)
      </div>
      <div className="TbdPrivacyPolicy__Block">
        In the preceding 12 months, we have not disclosed your personal
        information to any third party in a manner that would be considered a
        sale under the CCPA.
      </div>

      <div className="TbdPrivacyPolicy__SubHeading">
        8. Changes to our Privacy Policy
      </div>
      <div className="TbdPrivacyPolicy__Block">
        We will revise this Privacy Policy from time to time as our product
        evolves. If there are any material changes to this Privacy Policy, we
        will notify you as required by applicable law. You understand and agree
        that you will be deemed to have accepted the updated Privacy Policy if
        you continue to use the Services after the new Privacy Policy takes
        effect.
      </div>
      <div className="TbdPrivacyPolicy__Block">
        Translation: If we there are significant changes, we’ll let send an
        email! Using our product tells us that you’re ok with the way we’re
        handling privacy. If you feel otherwise, feel free to contact us and
        we’re happy to work with you to make our systems more secure 🙂
      </div>
      <div className="TbdPrivacyPolicy__SubHeading">Contact</div>
      <div className="TbdPrivacyPolicy__Block">
        <a className="TbdLink" href="mailto:team@trove.so">
          team@trove.so
        </a>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
