export const USER_CACHE_MAX_SIZE=50;
export const REQUEST_TIMEOUT=3000;
export const PAGINATION_AMOUNT=50;
export const DEFAULT_TOPIC_COLOR = '#BCE1FF';
export const DEFAULT_TOPIC_COLORS = [
  '#EBEAEA', // grey
  '#E8D5CC', // brown
  '#FDDECC', // orange
  '#FBEECC', // yellow
  '#CCE7E1', // green
  '#CCE4F9', // blue
  '#E0D3F9', // purple
  '#F8CCE6', // pink
  '#FFCCD1', // red
];
export const DEFAULT_USER_COLORS = [
  '#9900EF', // purple
  '#F78DA7', // pink
  '#EB144C', // red
  '#0693E3', // blue
  '#8ED1FC', // light blue
  '#00D084', // green
  '#FCB900', // yellow
  '#FF6900', // orange
];