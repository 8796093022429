import Home from "components/Pages/Home";
import MobileLanding from "components/Pages/MobileLanding";
import Onboarding from "components/Pages/Onboarding";
import PageNotFound from "components/Pages/PageNotFound";
import PrivacyPolicy from "components/Pages/PrivacyPolicy";
import ResetPassword from "components/Pages/ResetPassword";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { ErrorOrigin } from "server/misc";
import { REACT_APP_ANALYTICS_KEY } from "../constants/secrets";
import ErrorBoundary from "./ErrorBoundary";
import PrivateRoute from "./PrivateRoute";
import "./style.scss";

const App = observer(() => {
  useEffect(() => {
    if (REACT_APP_ANALYTICS_KEY) {
      // @ts-ignore
      window.analytics.load(REACT_APP_ANALYTICS_KEY);
    }
  }, []);

  if (window.innerWidth < 678) return <MobileLanding />;
  else
    return (
      <BrowserRouter>
        <ErrorBoundary origin={ErrorOrigin.Frontend}>
          <Switch>
            <PrivateRoute exact path="/" component={Home} />
            <Route exact path="/signup" component={Onboarding} />
            <Route exact path="/reset/:token" component={ResetPassword} />
            {/* <Route exact path='/new-user-highlights' component={AsciiArt}/> */}
            <Route exact path="/privacy" component={PrivacyPolicy} />
            <Route exact path="/404" component={PageNotFound} />
            {/* <PrivateRoute path='/:username/:postId' component={PostPage}/>
            <PrivateRoute path='/:username' component={Profile}/> */}
            <Redirect from="*" to="/404" />
          </Switch>
        </ErrorBoundary>
      </BrowserRouter>
    );
});

export default App;
