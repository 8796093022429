import { api, AxiosRes, BaseParams, BaseRes } from '.';
import IUser from '../state/models/IUser';
import userStore from '../state/stores/UserStore';

export type IUsersRes = UsersRes & AxiosRes;
export type IUserRes = UserRes & AxiosRes;
export type IFollowsRes = FollowsRes & AxiosRes;

export const followUser = async (userId: string): Promise<AxiosRes> => {
  const params: BaseParams = { id: userId };
  return await api.get(`/users/${params.id}/follow/create`);
}

export const unfollowUser = async (userId: string): Promise<AxiosRes> => {
  const params: BaseParams = { id: userId };
  return await api.get(`/users/${params.id}/follow/delete`);
}

export const suggestUsersToFollow = async (): Promise<IUsersRes> => {
  return await api.get('/users/follow/suggest');
}

export const getFollowersAndFollowing = async (username: string): Promise<IFollowsRes> => {
  const args: GetFollowsReqBody = { username };
  return await api.post('/users/follows', args);
}

export const handleUserSearch = async (usernamePrefix: string, numResults?: number): Promise<IUsersRes> => {
  const args: GetUsersReqBody = { usernamePrefix };
  if (numResults) args.numResults = numResults;
  return await api.post('/users', args);
}

export const getUser = async (id: string): Promise<IUserRes> => {
  const params: UserReqParams = { id };
  return await api.get(`/users/${params.id}`);
}

export const updateEmail = async (email: string): Promise<IUserRes> => {
  const params: BaseParams = { id: userStore.user!.id };
  const args: UpdateUserReqBody = { email }
  return await api.post(`/users/${params.id}/update`, args);
}

export const updatePhoneNumber = async (phoneNumber: string): Promise<IUserRes> => {
  const params: BaseParams = { id: userStore.user!.id };
  const args: UpdateUserReqBody = { phoneNumber }
  return await api.post(`/users/${params.id}/update`, args);
}

/**
 * POST /users/
 */
interface GetUsersReqBody {
  usernamePrefix: string;
  numResults?: number; // defaults to 10
}


/**
 * POST /users/follows
 */
interface GetFollowsReqBody {
  username: string;
}

/**
 * POST /users/:id/update
 */
interface UpdateUserReqBody {
  color?: string; // Hex code
  displayName?: string;
  email?: string; // either email, or phoneNumber
  phoneNumber?: string;
  username?: string; // alphanum and underscores, 3 < username < 20 characters
}

/**
 * GET /users/:id
 * POST /users/:id/update
 */
interface UserReqParams extends BaseParams {
  id: string;
}

/**
 * POST /users/
 */
type UsersRes = {
  users: IUser[];
} & BaseRes;

/**
 * GET /users/:id
 * POST /users/:id/update
 */
type UserRes = {
  user: IUser;
} & BaseRes;

/**
 * POST /users/follows
 */
type FollowsRes = {
  user?: IUser;
  followers?: IUser[];
  following?: IUser[];
} & BaseRes;