
import { action, observable } from 'mobx';
import { likePost, unlikePost } from 'server/posts';
import Topic from 'state/entities/Topic';
import { calculateTimeAgo, displayUrl } from 'utils';
import IPost from '../models/IPost';
import ITopic from '../models/ITopic';
import IUser from '../models/IUser';
import Highlight from './Highlight';
import User from './User';

export default class Post implements IPost {
  @observable public content: string;
  @observable public topics: Topic[];
  @observable public taggedUsers: User[]; // must contain values for parent post
  @observable public numComments: number;
  @observable public numLikes: number;
  @observable public highlight?: Highlight;
  @observable public comments?: Post[];
  @observable public liked: boolean;
  public id: string;
  public creationDatetime: number;
  public creator: User;
  public domain: string;
  public url: string;
  public parentPostId?: string;
  private loadDatetime: number;

  public constructor(p: IPost) {
    this.id = p.id;
    if (p.content.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
      this.content = '';
    } else this.content = p.content;
    this.creationDatetime = p.creationDatetime;
    this.creator = new User(p.creator);
    this.domain = p.domain;
    this.url = p.url;
    this.topics = p.topics.sort((t1, t2) => t2.creationDatetime - t1.creationDatetime);
    this.taggedUsers = p.taggedUsers.map((u) => new User(u));
    this.numComments = p.numComments;
    this.numLikes = p.numLikes;
    this.liked = p.liked;
    this.loadDatetime = Date.now();
    if (p.highlight) this.highlight = new Highlight(p.highlight);
    if (p.comments) this.comments = p.comments.map((p) => new Post(p));
    if (p.parentPostId) this.parentPostId = p.parentPostId
  }

  get timeAgo() {
    return calculateTimeAgo(this.creationDatetime);
  };

  get isTopOfThread() {
    return !this.parentPostId
  };
  
  get isComment() {
    return !!this.parentPostId
  };

  get displayUrl(): string {
    return displayUrl(this.url);
  }

  @action
  setTaggedUsers = (taggedUsers: IUser[]) => {
    this.taggedUsers = taggedUsers.map((tu) => new User(tu));
  }
  
  @action
  removeTopic = (topicId: string) => {
    this.topics = this.topics.filter((t) => t.id !== topicId)
  }

  @action
  addTopic = (newTopic: ITopic) => {
    this.topics.unshift(new Topic(newTopic));
  }

  @action
  setContent = (newContent: string) => {
    if (newContent.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
      this.content = '';
    } else this.content = newContent;
  }

  @action
  likePost = async () => {
    this.numLikes += 1;
    this.liked = true;
    return await likePost(this.id);
  }

  @action
  unlikePost = async () => {
    this.numLikes -= 1;
    this.liked = false;
    return await unlikePost(this.id);
  };

  @action
  addComment = async () => {
    this.numComments += 1
  }

  @action
  deleteComment = async () => {
    this.numComments -= 1
  }
};