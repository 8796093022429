import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import useStores from "state/stores";
import "./style.scss";

const PageNotFound = () => {
  const { authStore, userStore } = useStores();

  useEffect(() => {
    window.analytics.page("Page Not Found");
  }, [userStore.user, authStore.isAuthenticated]);

  return (
    <div className="TrovePageNotFound">
      <div className="TrovePageNotFound__Emoji">
        <span role="img" aria-label="blush-emoji">
          😳
        </span>
      </div>
      <br />
      <div className="TrovePageNotFound__Title">Page Not Found</div>
      <div className="TrovePageNotFound__Message">
        <p>
          Try going back to the homepage at
          <Link to="/" className="TbdLink" style={{ marginLeft: "5px" }}>
            <strong>trove.so</strong>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default PageNotFound;
