import { REQUEST_TIMEOUT } from 'constants/index';
import { EXTENSION_ID } from 'constants/secrets';
import IUser from 'state/models/IUser';
import authStore from 'state/stores/AuthStore';

/**
 * Message to send to extension 
 */
export interface Message {
  type: MessageType;
  isAuthenticated?: boolean;
  token?: string;
  user?: IUser;
}

/**
 * Message type for communicating with extension (recieving and sending)
 */
export enum MessageType {
  isAuthenticated = 'IS_AUTHENTICATED',
  Exists = 'EXISTS',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  UpdateProfile = 'UPDATE_PROFILE', // only receiving done
}

export const sendMessageToExtension = (message: Message) => {
    return new Promise((resolve) => {
      try {
        if (!chrome) {
          authStore.hasExtension = false;
          return resolve(null);
        }
        chrome.runtime.sendMessage(EXTENSION_ID, message, (response: any) => {
          const err = chrome.runtime.lastError;
          if (err) {
            authStore.hasExtension = false;
            console.error(err.message);
            return null;
          } else {
            resolve(response);
          }
        });
      } catch (err) {
        console.error(err.message)
        resolve(null)
      }
      setTimeout(() => resolve(null), REQUEST_TIMEOUT);
    });
}