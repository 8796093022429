import IPost from 'state/models/IPost';
import IUser from 'state/models/IUser';
import { api, AxiosRes, BaseParams, BaseRes } from '.';
import { TextRange } from '../state/models/IHighlight';
import ITopic from '../state/models/ITopic';

type IPostsRes = PostsRes & AxiosRes;
export type IPostRes = PostRes & AxiosRes;
export type ITaggedUsersRes = TaggedUsersRes & AxiosRes;

export const getPosts = async (args: GetPostsReqBody): Promise<IPostsRes> => {
  return await api.post('/posts/', args);
};

export const getPost = async (postId: string): Promise<IPostRes> => {
  const params: BaseParams = { id: postId };
  return await api.get(`/posts/${params.id}`);
}

export const getFeed = async (): Promise<IPostsRes> => {
  return await api.get('/posts/feed');
}

export const createPost = async (args: CreatePostReqBody): Promise<IPostRes> => {
  return await api.post('/posts/create', args);
}

export const createComment = async (parentPostId: string, args: CreateCommentReqBody): Promise<IPostRes> => {
  const params: BaseParams = { id: parentPostId };
  return await api.post(`/posts/${params.id}/comment/create`, args);
}

export const deletePostAndChildren = async (postId: string): Promise<AxiosRes> => {
  const params: BaseParams = { id: postId };
  return await api.get(`/posts/${params.id}/delete`);
}

export const editPost = async (postId: string, args: EditPostReqBody): Promise<ITaggedUsersRes> => {
  const params: BaseParams = { id: postId };
  return await api.post(`/posts/${params.id}/update`, args);
}

export const likePost = async (postId: string): Promise<AxiosRes> => {
  const params: BaseParams = { id: postId };
  return await api.get(`/posts/${params.id}/like/create`);
}

export const unlikePost = async (postId: string): Promise<AxiosRes> => {
  const params: BaseParams = { id: postId };
  return await api.get(`/posts/${params.id}/like/delete`);
}

/**
 * POST /posts/
 * Getting posts for a url versus your posts
 * Args narrow scope of posts. If no args, returns all of your posts
 * If url, then all posts on url
 * If url and userId or username, all posts for the specified user
 */
type GetPostsReqBody = {
  url?: string;
  userId?: string;
  username?: string; // same filtering effect as above, if you want to search by username
}

/**
 * POST /posts/create
 */
interface CreatePostReqBody {
  highlight: HighlightParam;
  url: string;
  content?: string;
  taggedUserIds?: string[];
  topics?: Partial<ITopic>[];
}

type HighlightParam = {
  textRange: TextRange; // Serialized Range object
  url: string;
};

/**
 * POST /posts/:id/comment/create
 */
export interface CreateCommentReqBody {
  url: string;
  content?: string;
  highlight?: HighlightParam;
  topics?: ITopic[];
}

/**
 * POST /posts/:id/update
 */
interface EditPostReqBody {
  content?: string;
  taggedUserIds?: string[];
  topics?: ITopic[];
}

/** ************************* */
/** ********** RES ********** */
/** ************************* */

/**
 * POST /posts/
 */
type PostsRes = {
  posts?: IPost[]; // does not include comments for each post
  taggedPosts?: IPost[];
  user?: IUser; // only sent when user not found
} & BaseRes;

/**
 * GET /posts/:id
 * POST /posts/create
 * POST /posts/:id/comment/create
 */
type PostRes = {
  thread?: IPost[]; // first index is parent ([parent, child, child of child, ...])
  post?: IPost; // includes comments
} & BaseRes;

type TaggedUsersRes = {
  taggedUsers?: IUser[];
} & BaseRes;
